<template>
  <div>
    <b-row>
      <b-col class="col-6">
        <h3>Daftar Pencarian Orang</h3>
        <loading v-if="isLoading"></loading>
        <b-table v-if="!isLoading" striped hover :items="listDPO" :fields="dpoFields" :per-page="perPage" :current-page="currentPageDPO" @row-clicked="gotodetaildpo" show-empty>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPageDPO" :total-rows="listDPO.length" :per-page="perPage"></b-pagination>
        </div>
      </b-col>
      <b-col class="col-6">
        <h3>Daftar Pencarian Kendaraan</h3>
        <loading v-if="isLoading"></loading>
        <b-table v-if="!isLoading" striped hover :items="listDPK" :fields="dpkFields" :per-page="perPage" :current-page="currentPageDPK" @row-clicked="gotodetaildpk" show-empty>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPageDPK" :total-rows="listDPK.length" :per-page="perPage"></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios
  },
  data() {
    return {
      perPage: 5, // Jumlah item per halaman
      currentPageDPK: 1, // Halaman saat ini
      currentPageDPO: 1, // Halaman saat ini
      isLoading: false,
      dpoFields: [
        {key:"fullname",label:"Nama"},
        {key:"tanggal_lahir",label:"Tanggal Lahir"},
        {key:"periode",label:"Periode"},
      ],
      dpkFields: [
        {key:"modelname",label:"Nama"},
        {key:"plate",label:"Plat Nomor"},
        {key:"periode",label:"Periode"},
      ],
      listDPO: [],
      listDPK: [],
    };
  },
  methods: {
    init(){
      this.getInfoDPO();
    },
    gotodetaildpo(item, index, event) {
      this.$router.push({ path: "/idp-warga-detail/"+item.id });
    },
    gotodetaildpk(item, index, event) {
      this.$router.push({ path: "/idp-kendaraan-detail/"+item.plate });
    },
    getInfoDPO() {
      this.isLoading = true;
      axios.post("idp/dpo/list",{id:null}).then((response) => {
        this.listDPO = response.data.data_dpo
        this.listDPK = response.data.data_dpk
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.isLoading = false;
        this.modalShow = false;
      });
    },
    viewDPO(e){
      this.$router.push({ path: "/idp-warga-detail/"+e.id });
    },
    viewDPK(e){
      this.$router.push({ path: "/idp-kendaraan-detail/"+e.plate });
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style scoped>
.wanted-text {
    font-family: 'Impact', sans-serif; /* Gunakan font yang memiliki tampilan tebal dan mencolok */
    font-size: 20px; /* Sesuaikan ukuran font sesuai kebutuhan */
    text-transform: uppercase; /* Ubah teks menjadi huruf besar */
    color: gray; /* Gunakan warna putih untuk teks */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Tambahkan shadow dengan ukuran dan warna yang diinginkan */
}

.wanted-overlay {
    position: relative;
    display: inline-block;
}

.wanted-image {
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
}

@keyframes blinking {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: white; /* Ubah warna teks menjadi merah */
    background-color: red;
    padding: 10px 20px;
    border-radius: 5px;
    animation: blinking 1s infinite alternate; /* Terapkan animasi blinking */
}

.overlay-text-dpk {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: white; /* Ubah warna teks menjadi merah */
    background-color: red;
    padding: 10px 20px;
    border-radius: 5px;
    animation: blinking 1s infinite alternate; /* Terapkan animasi blinking */
}
</style>